import { useClassName } from '@/styles/SliderSwiper';
import { Component, ComponentProps, PropsFromComponent, PropsFromSubcomponents } from '@/types/component';
import { GenericComponentFactory } from 'base/components/GenericComponent';
import gridBreakpoints from 'base/configs/grid.breakpoints';
import { useCallback, useState } from 'react';
import { Autoplay as AutoplayModule, Navigation as NavigationModule } from 'swiper/modules';
import { Swiper as SwiperReact } from 'swiper/react';
import { SwiperOptions } from 'swiper/types';
import { isNumber } from 'typesafe-utils';
import { SwiperArrowComponent } from './Swiper.Arrow';

const defaultBreakpoints = {
  [gridBreakpoints.xs]: {
    slidesPerView: 2,
  },
  [gridBreakpoints.sm]: {
    slidesPerView: 3,
  },
  [gridBreakpoints.md]: {
    slidesPerView: 4,
  },
  [gridBreakpoints.lg]: {
    slidesPerView: 5,
  },
  [gridBreakpoints.xl]: {
    slidesPerView: 5,
  },
};

const modules = [AutoplayModule, NavigationModule];

export interface SwiperProps extends ComponentProps<typeof SwiperReact> {}

export const SwiperComponent: Component<SwiperProps> = ({ children, ...props }) => {
  const [currentSlidesPerView, setCurrentSlidesPerView] = useState<number>(3);
  const className = useClassName('', props);
  const shouldLoop = Array.isArray(children) && children?.length / 2 >= currentSlidesPerView;

  const stableProps = {
    speed: 1000,
    autoplay: { delay: 3000 },
    loop: shouldLoop,
    spaceBetween: 24,
    breakpoints: defaultBreakpoints,
    ...props,
    className: `${className} ${props?.className || ''}`,
  };

  // Docs: because of nature of how the loop mode works (it will rearrange slides),
  // total number of slides must be >= slidesPerView * 2
  const onBreakpoint = useCallback((_: any, breakpointParams: SwiperOptions) => {
    setCurrentSlidesPerView(isNumber(breakpointParams.slidesPerView) ? breakpointParams.slidesPerView : 3);
  }, []);

  return (
    <SwiperReact {...{ modules, onBreakpoint }} {...stableProps}>
      {children}
    </SwiperReact>
  );
};

export type SwiperNavigationProps = PropsFromComponent<typeof Swiper>;
export type SwiperNavigationSubcomponentsProps = PropsFromSubcomponents<typeof Swiper>;

const GenericComponent = GenericComponentFactory({ useClassName });

const Content = GenericComponent({ styles: { key: 'content' } });
const Group = GenericComponent({ styles: { key: 'group' } });
const Title = GenericComponent({ as: 'h2', styles: { key: 'title' } });
const Navigation = GenericComponent({ styles: { key: 'navigation' } });
const NavigationItem = GenericComponent({ styles: { key: 'navigation_item' } });

export const Swiper = Object.assign(SwiperComponent, {
  Arrow: SwiperArrowComponent,
  Content,
  Group,
  Title,
  Navigation: Object.assign(Navigation, {
    Item: NavigationItem,
  }),
});
