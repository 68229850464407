import { isProduction } from 'lib/labrador/utils';

export const JW_BASE_URL = 'https://cdn.jwplayer.com';
export const JW_THUMBNAILS_URL = `${JW_BASE_URL}/thumbs`;
export const JW_CDN_URL = `${JW_BASE_URL}/v2`;

export type brandType =
  | 'allas'
  | 'elle'
  | 'femina'
  | 'mabra'
  | 'residence'
  | 'recept'
  | 'baaam'
  | 'frida'
  | 'motherhood'
  | 'hant'
  | 'svenskdam';

export const B2B_USER_INFO = 'b2bVideoInfo';
export const JW_USER_ID = 'alAVThkH';

export const AD_SCHEDULE_ID = {
  ATP: {
    allas: 'a4kkgt0P',
    elle: 'RBpAHqAL',
    femina: 'uBH5g2Sr',
    mabra: 'oP4lk47h',
    residence: 'OhFmmo3F',
    motherhood: 'ZUkc4M5g',
    hant: 'DrX1nVjT',
    svenskdam: 'DfBHqYZx',
  },
  CTP: {
    allas: '0XDonXQi',
    elle: 'hpHCqkob',
    femina: 'VYCGmwt4',
    mabra: 'UDNmLZsj',
    residence: 'ib9PwBrj',
    motherhood: 'uES06Oub',
    hant: 'DZo5Gx0b',
    svenskdam: 'JcHd23Vg',
  },
  VP: {
    allas: 'na7OwFdn',
    elle: 'UbenDR0O',
    femina: 'y6mToUfQ',
    mabra: '8bWwHPz2',
    residence: 'Ik9YMXOO',
    hant: 'M2hfYQ8W',
    svenskdam: 'TvfralFZ',
  },
};

//For aspect ratio of videos
export const HORIZONTAL_ASPECT_RATIO = '16:9';
export const VERTICAL_ASPECT_RATIO = '9:16';

//For video orientation
export const VIDEO_ORIENTATION_CLASS: { [key: string]: string } = {
  [HORIZONTAL_ASPECT_RATIO]: 'customized_sticky h_player',
  [VERTICAL_ASPECT_RATIO]: 'customized_sticky v_player',
};

//For next video recommedation
export const JW_MEDIA_URL = `${JW_CDN_URL}/media`;
export const VIEWED_VIDEOS = 'jwplayer.viewedVideos';
export const VIEWED_VERTICAL = 'jwplayer.viewedVertical';
export const RECOMMENDATION_API_URL = isProduction
  ? 'https://aap-co-author-ep-hjvue43d5a-ew.a.run.app/playlist-recommendation/'
  : 'https://aap-co-author-ep-7uqc6lypwa-ew.a.run.app/playlist-recommendation/';
